import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const DebouncedInput = ({
  value: initValue,
  onChange,
  debounce = 500,
  ...props
}) => {
  const [value, setValue] = useState(initValue);
  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);
    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
};

const Subscription = () => {
  const columnHelper = createColumnHelper();
  const [data, setData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const navigate=useNavigate();
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/admin/subscription`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred";
        toast.error(errorMessage);
      });
  }, []);

//     "invoice_url": "https://invoice.stripe.com/i/acct_1Nkh21R2FfpAxaQM/test_YWNjdF8xTmtoMjFSMkZmcEF4YVFNLF9SSGVyTjdwS0FyOE9tQ09Nd0lPN2VoZ3VaSU9qT2xsLDEyMzA5NDQxMQ0200Utcb8tcM?s=ap",


  const columns = [
    columnHelper.accessor("id", {
      id: "S.No",
      cell: (info) => <span>{info.row.index + 1}</span>,
      header: "S.No",
    }),
    columnHelper.accessor("userId.name", {
      cell: (info) => (
        <div className="line-clamp-3">

          {info.getValue()}
        </div>
      ),
      header: "User",
    }),

    columnHelper.accessor("userId.email", {
      cell: (info) => <span>{info.getValue()}</span>,
      header: "Email",
    }),
    columnHelper.accessor("subscriptionId", {
      cell: (info) => <span>{
        // truncate the string
        info.getValue()
      }</span>,
      header: "Subscription ID",
    }),
    columnHelper.accessor("status", {
      cell: (info) => <span>{info.getValue()}</span>,
      header: "Status",
    }),
    columnHelper.accessor("current_period_start", {
      cell: (info) => (
        <span>{moment(info.getValue()).format("MMMM Do YYYY")}</span>
      ),
      header: "Start Date",
    }),
    columnHelper.accessor("current_period_end", {
      cell: (info) => (
        <span>{moment(info.getValue()).format("MMMM Do YYYY")}</span>
      ),
      header: "End Date",
    }),
     
    columnHelper.accessor("amount", {
      cell: (info) => <span>$ {
        // it is in cents so divide by 100
        info?.getValue()?info?.getValue()/100:0
      }</span>,
      header: "Amount",
    }),
    
    
    columnHelper.accessor("actions", {
      cell: (info) => (
        <div className="flex flex-col gap-4 w-full min-w-20">
          {/* <button
            onClick={() =>
              (window.location.href = `/chat/${info.row.original._id}`)
            }
            className="rounded transition-all w-full bg-white px-3 py-2 text-xs shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Chats
          </button> */}
          <button
            onClick={() =>
              window.open(info.row.original.invoice_url, "_blank")
            }
            className="text-xs rounded transition-all w-full bg-black px-3 py-2  text-white shadow-sm hover:opacity-75"
          >
           Receipt
          </button>
        </div>
      ),
      header: "Actions",
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div className="bg-white p-2 mx-auto fill-gray-400">
      <ToastContainer />
      <div className="flex justify-between mb-2">
        <div className="w-full flex items-center gap-1">
          <i className="fas fa-search"></i>
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            className="p-2 bg-transparent outline-none border-b-2 w-1/5 focus:w-1/3 duration-300 border-gray-300"
            placeholder="Search all columns..."
          />
        </div>
      </div>
      <table className="w-full text-left">
        <thead className="bg-black text-white">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="capitalize px-3.5 py-2">
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length ? (
            table.getRowModel().rows.map((row, i) => (
              <tr
                key={row.id}
                className={`${i % 2 === 0 ? "bg-white" : "bg-gray-100"}`}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="px-3.5 py-4 text-black">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr className="text-center h-32">
              <td colSpan={12}>
                {" "}
                <i className="fa-solid fa-spinner animate-spin text-2xl"></i>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="flex items-center justify-end mt-2 gap-2">
        <button
          onClick={() => {
            table.previousPage();
          }}
          disabled={!table.getCanPreviousPage()}
          className="p-1 border border-gray-700 px-2 disabled:opacity-50 text-gray-700"
        >
          {"<"}
        </button>
        <button
          onClick={() => {
            table.nextPage();
          }}
          disabled={!table.getCanNextPage()}
          className="p-1 border border-gray-700 px-2 disabled:opacity-50 text-gray-700"
        >
          {">"}
        </button>

        <span className="flex items-center gap-1 text-gray-700">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
        </span>
        <span className="flex items-center gap-1 text-gray-700">
          | Go to page:
          <input
            type="number"
            min={1}
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className="border border-gray-700 p-1 rounded w-16 outline-none focus:outline-none"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
          className="p-1 border border-gray-700 text-gray-700"
        >
          {[5, 10, 25, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Subscription;
